




















import Vue from 'vue';
import PopUp from '@/configurator/components/utils/PopUp.vue';
import OpenARView from '@/configurator/components/overlays/ar/OpenARView.vue';
import {Nullable} from '@/common/utils/types';
import {GA_CATEGORY} from '@/common/utils/google-analytics';
import {removeLoadingScreen, showLoadingScreen} from '@/common/utils/dom';
import {isAndroid, isIOs} from '@/common/utils/user-agent';
import {getGlbAssetUrl, getUsdzAssetUrl} from '@/common/utils/helper';
import {UiState} from '@/configurator/store/ui-state';

export default Vue.extend({
  components: {
    PopUp,
    OpenARView,
  },

  props: {
    onClose: Function,
  },

  data() {
    return {
      configurationId: null as Nullable<string>,
      imageUrl: null as Nullable<string>,
      gltfUrl: null as Nullable<string>,
      usdzUrl: null as Nullable<string>,
    };
  },

  computed: {
    configuratorId(): string {
      return this.$store.state.uiState.initData.configuratorId;
    },
    isDesktop() {
      return !isAndroid() && !isIOs();
    },
  },

  async mounted() {
    showLoadingScreen();
    // @ts-ignore
    const wallParam: KernelParameter = {
      key: 'showWall',
    };
    await this.$sdkConnector.setParameter(wallParam, 'hide');
    const id = ((this.$store.state.uiState as UiState).lastRequestedId || (this.$store.state.uiState as UiState).initData?.id)!;
    const isViewer = await this.$sdkConnector.isIdAGlb(id);
    if (isViewer) {
      const rapiAccess = await this.$sdkConnector.getRapiAccess();
      const item = await rapiAccess.getItem(id);
      this.gltfUrl = getGlbAssetUrl(item);
      this.usdzUrl = getUsdzAssetUrl(item);
      this.imageUrl = item.perspectiveImage || item.perspectiveImageHD;
      this.configurationId = id;
    } else {
      const currentConfiguration = await this.$sdkConnector.saveCurrentConfiguration();
      this.imageUrl = currentConfiguration.perspectiveImage || null;
      this.gltfUrl = getGlbAssetUrl(currentConfiguration);
      this.usdzUrl = getUsdzAssetUrl(currentConfiguration);
      this.configurationId = currentConfiguration.id;
    }
    this.$analytics.ga.trackEvent('ARButtonClicked', GA_CATEGORY.INTERACTION, this.configurationId!);
    removeLoadingScreen();
  },
});
