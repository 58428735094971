




























































































import Vue from 'vue';
import PopUp from '@/configurator/components/utils/PopUp.vue';
import AspectRatioBox from '@/configurator/components/utils/AspectRatioBox.vue';
import {Nullable} from '@/common/utils/types';
import ARService from '@/common/utils/ar-service';
import {copyToClipboard} from '@/common/utils/dom';
// @ts-ignore
import {overlayBackgroundGray} from '@/configurator/components/overlays/ar/-utils/OpenARView.module.scss';
import {hasARQuicklook, isAndroid, isInAppBrowser} from '@/common/utils/user-agent';

export default Vue.extend({
  components: {
    PopUp,
    AspectRatioBox,
    // @ts-ignore
    VueQRCodeComponent: () => import('vue-qrcode-component'),
  },

  props: {
    configurationId: String,
    imageUrl: String,
    configuratorId: String,
    gltfUrl: String,
    usdzUrl: String,
    isDesktop: Boolean,
    userInteraction: {
      type: Boolean,
      default: true,
    },
    onClose: Function,
  },

  data() {
    return {
      arService: null as Nullable<ARService>,
    };
  },

  computed: {
    overlayBackgroundGray(): string {
      return overlayBackgroundGray;
    },
    mobileArUrl(): string | null {
      return this.configurationId ? this.arService?.getMobileArUrl(this.configurationId) || null : null;
    },
    quickViewAvailable(): boolean {
      const result = hasARQuicklook();
      if (typeof result === 'boolean') {
        return result;
      }
      // we do not know, so we assume AR Quicklook is there and hope for the best
      return true;
    },
    inAppBrowser(): boolean {
      const result = isInAppBrowser();
      if (typeof result === 'boolean') {
        return result;
      }
      // we do not know, so we assume we are not in an in-app browser and hope for the best
      return false;
    },
    arUrl(): string | null {
      if (this.gltfUrl && this.usdzUrl) {
        return this.arService?.getArUrlWithUrls(this.gltfUrl, this.usdzUrl) || null;
      } else {
        return this.arService?.getArUrl(this.configurationId) || null;
      }
    },
  },

  methods: {
    copyToClipboard() {
      if (this.mobileArUrl) {
        copyToClipboard(this.mobileArUrl);
        if (this.$props.onClose) {
          this.$props.onClose();
        }
      }
    },
    async close() {
      if (this.$props.onClose) {
        this.$props.onClose();
        // @ts-ignore
        const wallParam: KernelParameter = {
          key: 'showWall',
        };
        await this.$sdkConnector.setParameter(wallParam, 'show');
      }
    },
  },

  mounted() {
    this.arService = new ARService(this.configuratorId);
    this.$nextTick(() => {
      // open url directly when we are on Android and this was triggered by an user interaction
      // on iOS this does not work since a link with rel="ar" has to be clicked
      if (isAndroid() && this.arUrl && this.userInteraction) {
        window.open(this.arUrl);
        this.close();
      }
    });
  },
});
