import {isAndroid, isIOs} from '@/common/utils/user-agent';

export default class ARService {

  private _configuratorId: string = '';

  constructor(configuratorId: string) {
    this._configuratorId = configuratorId;
  }

  public getArUrl(configurationId: string): string | null {
    return this.getArUrlWithUrls(this._getGlbUrl(configurationId), this._getUsdzUrl(configurationId));
  }

  public getArUrlWithUrls(glbUrl: string | null = null, usdzUrl: string | null = null): string | null {
    if (isIOs() && usdzUrl) {
      return usdzUrl;
    } else if (isAndroid() && glbUrl) {
      return this._getAndroidSceneViewerUrl(glbUrl);
    }
    return null;
  }

  public getMobileArUrl(id: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('id', id);
    if (this._configuratorId) {
      urlParams.set('configuratorId', this._configuratorId);
    }
    let pathName = window.location.pathname ? window.location.pathname : '/';
    const folders = pathName.split('/');
    const foldersLength = folders.length;
    const fileName = (foldersLength && folders[foldersLength - 1].includes('.')) ? folders[foldersLength - 1] : null;
    if (fileName) {
      pathName = pathName.replace(fileName, '');
    }
    const url = window.location.origin + pathName;
    const pathSeparator = (url.endsWith('/')) ? '' : '/';
    return url + pathSeparator + 'ar.html' + '?' + urlParams;
  }

  private _getAndroidSceneViewerUrl(glbUrl: string) {
    return 'intent://arvr.google.com/scene-viewer/1.0?file=' + glbUrl
      + '&mode=ar_only'
      + '&resizable=false'
      + '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;end;';
  }

  private _getBaseUrl(): string {
    return process.env.VUE_APP_BASE_URL || 'https://roomle.com/';
  }

  private _getAndroidLink(deeplinkUrl: string, locale: string) {
    return this._getBaseUrl() + locale + '/android/get-the-app?deeplink=' + this._encodeUrl(deeplinkUrl);
  }

  private _getIosLink(deeplinkUrl: string, locale: string) {
    return this._getBaseUrl() + locale + '/ios/get-the-app?deeplink=' + this._encodeUrl(deeplinkUrl);
  }

  private _encodeUrl(deeplinkUrl: string) {
    return encodeURIComponent(deeplinkUrl);
  }

  private _getGlbUrl(configurationId: string) {
    return process.env.VUE_APP_RAPI_URL + '/configurations/' + configurationId + '/3dAssets/glTF/conf.glb';
  }

  private _getUsdzUrl(configurationId: string) {
    return process.env.VUE_APP_RAPI_URL + '/configurations/' + configurationId + '/3dAssets/usdz/conf.usdz';
  }
}
